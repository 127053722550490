define("discourse/plugins/discourse-user-notes/discourse/helpers/add-user-path", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = addUserPath;
  function addUserPath(user) {
    user.path = (0, _url.userPath)(user.username.toLowerCase());
    return user;
  }
});