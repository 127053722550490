define("discourse/plugins/discourse-user-notes/discourse/helpers/format-timestamp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = formatTimestamp;
  function formatTimestamp(date) {
    date = moment(date).tz("UTC");
    return `${date.format("YYYY-MM-DD hh:mm:ss A")} UTC`;
  }
});